<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<script>
import { MetaInfoMixin } from '@mixins'
import SettingComponent from '@components/landing/SettingComponent'

export default {
  name: 'TermsAndConditions',
  extends: SettingComponent,
  mixins: [MetaInfoMixin],
  data: () => ({
    string: 'terms_and_conditions'
  })
}
</script>
