<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->

<template>
  <app-card
    :title="parseAttribute(string)"
  >
    <v-container>
      <v-skeleton-loader
        v-if="skeleton"
        class="pa-2"
        type="article@2"
      />
      <v-row
        v-else
        style="min-height: 200px"
      >
        <v-col cols="12">
          <div v-html="text" />
        </v-col>
      </v-row>
    </v-container>
  </app-card>
</template>

<script>
export default {
  name: 'SettingComponent',
  data: () => ({
    skeleton: !0,
    string: null
  }),
  computed: {
    text () {
      return this.AppSettings[this.string]
    }
  },
  mounted () {
    (async () => {
      await this.fetchAppSettings()
      this.skeleton = !1
    })()
  }
}
</script>
